var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "slide-y-transition",
        "nudge-left": "40",
        "offset-y": "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { class: _vm.body, attrs: { icon: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      ),
                    ]
                  },
                  { attrs: attrs, on: on }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "300" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.actions, function (action, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i,
                    attrs: { disabled: action.disabled },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.actionClicked(action.component)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-action",
                      { attrs: { disabled: action.disabled } },
                      [_c("v-icon", [_vm._v(_vm._s(action.icon))])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      { staticClass: "mr-3" },
                      [
                        _c("v-list-item-title", { class: _vm.body }, [
                          _vm._v(_vm._s(action.label)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: !_vm.orderGridSubmitEnabled },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.submitOrderGrid(_vm.ad)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(" mdi-email-fast-outline ")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "mr-3" },
                    [
                      _c("v-list-item-title", { class: _vm.body }, [
                        _vm._v(" Send To Order Grid "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              ["Draft", "Submitted"].includes(_vm.ad.status)
                ? _c(
                    "span",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.ad.is_processing },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.generateAdPlanner(_vm.ad)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            {
                              attrs: {
                                disabled:
                                  !["Draft", "Submitted"].includes(
                                    _vm.ad.status
                                  ) || _vm.ad.is_processing,
                              },
                            },
                            [
                              ["Draft", "Submitted"].includes(_vm.ad.status)
                                ? _c(
                                    "span",
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-clock-start "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "mr-3" }, [
                            ["Draft", "Submitted"].includes(_vm.ad.status)
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { class: _vm.body },
                                      [_vm._v(" Generate Ad Planner ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              ["Approved"].includes(_vm.ad.status) &&
              ["awg", "alliance-retail-group"].includes(_vm.$auth.tenant) &&
              _vm.ad.ad_planner_generated
                ? _c(
                    "span",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.ad.is_processing },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.actionClicked("GenAdPlanFiles")
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            { attrs: { disabled: _vm.ad.is_processing } },
                            [
                              _c(
                                "span",
                                [_c("v-icon", [_vm._v(" mdi-clock-start ")])],
                                1
                              ),
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "mr-3" }, [
                            _c(
                              "span",
                              [
                                _c("v-list-item-title", { class: _vm.body }, [
                                  _vm._v(" Generate Ad Planner Files "),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ad.ad_planner_generated
                ? _c(
                    "span",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              ;(_vm.component = "ad_planner_view"),
                                (_vm.modal = true)
                            },
                          },
                        },
                        [
                          _c("v-list-item-action", [
                            _vm.ad.ad_planner_generated
                              ? _c(
                                  "span",
                                  [_c("v-icon", [_vm._v("mdi-text")])],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("v-list-item-content", { staticClass: "mr-3" }, [
                            _vm.ad.ad_planner_generated
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { class: _vm.body },
                                      [_vm._v(" View Ad Planner ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(_vm.component, {
            key: _vm.componentKey,
            tag: "Component",
            attrs: {
              value: _vm.modal,
              selectedAd: _vm.ad,
              defaultParty: _vm.adParty,
              parties: _vm.adGroups,
              direction: _vm.direction,
            },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
              refresh: function ($event) {
                return _vm.$emit("refresh")
              },
              closeAndRefresh: _vm.closeAndRefresh,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }